.btn_primary {
    color: #fff;
    border-radius: 0.5rem;
    background-color: #e20612;
    border: none;
    padding: 0.5rem 1.5rem !important;
    box-shadow: 5px 5px 10px rgb(55 94 148 / 10%),
        -5px -5px 10px rgb(255 255 255 / 20%);
}
.card_custom {
    border-radius: 4px;
    border-top: 4px solid red;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
}
.card_custom2 {
    border-radius: 4px;
    border-top: 4px solid green;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
    padding: 10px;
}
.card_custom3 {
    border-radius: 4px;
    border-top: 4px solid blue;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
    padding: 10px;
}
.card_custom4 {
    border-radius: 4px;
    border-top: 4px solid yellow;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
    padding: 10px;
}
.me {
    margin-right: 4rem;
}
.head {
    font-weight: 600;
    color: #000;
}
.card_topic {
    border-radius: 4px;
    border: unset;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
    padding: 5px;
}

.head_topic {
    font-size: 14px;
    color: #000;
}
.butan {
    font-size: 10px;
    border-radius: 4px;
    border: unset;
    padding: 3px 8px;
    margin-left: 5px;
    color: #fff;
    background-image: linear-gradient(
        to right top,
        #540505,
        #79050d,
        #a0040f,
        #c8040f,
        #f10a0a
    );
}
.butan2 {
    font-size: 10px;
    border-radius: 4px;
    border: unset;
    padding: 3px 8px;
    margin-left: 5px;
    color: #fff;
    background-image: linear-gradient(
        to right top,
        #133e05,
        #1a5508,
        #226d09,
        #2a870b,
        #32a10c
    );
}
.butan3 {
    font-size: 10px;
    border-radius: 4px;
    border: unset;
    padding: 3px 8px;
    margin-left: 5px;
    color: #fff;
    background-image: linear-gradient(
        to right top,
        #020736,
        #00135e,
        #001987,
        #001cb1,
        #2b18dc
    );
}
.butan4 {
    font-size: 10px;
    border-radius: 4px;
    border: unset;
    padding: 3px 8px;
    margin-left: 5px;
    color: #fff;
    background-image: linear-gradient(
        to right top,
        #bd9701,
        #c6a603,
        #ceb608,
        #d6c60f,
        #dcd618
    );
}
.card_news {
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
    padding: 5px;
    border-radius: 0px;
}
.card_news p {
    font-size: 12px;
    color: #000;
    text-align: justify;
    line-height: 1.4;
}
.card_news span {
    color: #000;
    font-size: 12px;
}
.card_news h6 {
    color: #000;
    font-weight: 600;
}

.form-control {
    padding: 0.5rem 0.5rem !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #e20612 !important;
    border-color: #dee2e6 #dee2e6 #e20612 !important;
}
.text-primary {
    color: #e20612 !important;
}

#publicSentiment {
    width: 100%;
    height: 500px;
}

#postAnatomy_1 {
    width: 100%;
    height: 450px;
    max-width: 100%;
}
#postAnatomy_2 {
    width: 100%;
    height: 450px;
    max-width: 100%;
}
#postAnatomy_3 {
    width: 100%;
    height: 450px;
    max-width: 100%;
}
#postAnatomy_4 {
    width: 100%;
    height: 450px;
    max-width: 100%;
}
.img_width {
    width: 20px;
}
.img_width2 {
    width: 16px;
}

#latestNews {
    width: 100%;
    height: 350px;
}

.ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.ellipsis-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.btn_outline_danger {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid red;
    padding: 0.25rem 0.5rem;
    color: red;
}
