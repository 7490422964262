.table_head {
    background-color: #4574c5;
    color: #fff !important;
    border: 1px solid #828282;
    text-align: center;
}
.table_head th {
    border: 1px solid #828282;
}
.table {
    padding: 0.25rem 0.5rem;
    color: #000;
}
.table_body {
    text-align: center;
}
.table_body td {
    border: 1px solid #828282;
}
.select_custom {
    word-wrap: normal;
    background-color: #adb6d3;
    padding: 0.25rem 0.25rem;
    border-radius: 6px;
    color: #e20612;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: #ddebfd;
    color: #000;
}
