.backdrop {
    position: fixed;
    top: 5.6rem;
    left: 0;
    width: 100%;
    height: 100vh;
    background: grey;
    opacity: 0.2;
    z-index: 10;
  }
  