@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.8;
    background-image: url('../public/assets/DataBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;
}

.com-logo {
    width: 9rem;
}

#typeOfComments {
    width: 100%;
    height: 300px;
}

#typeOfQueries {
    width: 100%;
    height: 300px;
}

#typeOfFeedback {
    width: 100%;
    height: 300px;
}

#publicEngagementByPost {
    width: 100%;
    height: 300px;
}

#typeOfComplainNegativeComments {
    width: 100%;
    height: 300px;
}

#typesOfAppreciation {
    width: 100%;
    height: 300px;
}

.loading-icon {
    width: 9rem;
}
.terms_cont {
    color: #000;
}
.term_head {
    color: #000;
    font-weight: 600;
}
.termsbody {
    text-align: justify;
}
