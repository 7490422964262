.side-drawer {
    position: fixed;
    left: 0;
    top: 6rem;
    z-index: 100;
    width: 60%;
    background: white;
    box-shadow: 0 2px 8px rgba(102, 99, 99, 0.26);
    border-radius: 0px 5px 0px 0px;
    height: 100%;
  }
  
  .slide-in-left-enter {
    transform: translateX(-100%);
  }
  
  .slide-in-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .slide-in-left-exit {
    transform: translateX(0%);
    opacity: 1;
  }
  
  .slide-in-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 200ms;
  }
  