.btn_primary {
    color: #fff;
    border-radius: 0.5rem;
    background-color: #e20612;
    border: none;
    padding: 0.5rem 1.5rem !important;
    box-shadow: 5px 5px 10px rgb(55 94 148 / 10%),
        -5px -5px 10px rgb(255 255 255 / 20%);
}

.form-control {
    padding: 0.5rem 0.5rem !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #e20612 !important;
    border-color: #dee2e6 #dee2e6 #e20612 !important;
}
.text-primary {
    color: #e20612 !important;
}

#publicSentiment {
    width: 100%;
    height: 500px;
}

#postAnatomy_1 {
    width: 100%;
    height: 500px;
    max-width: 100%;
}
#postAnatomy_2 {
    width: 100%;
    height: 500px;
    max-width: 100%;
}
#postAnatomy_3 {
    width: 100%;
    height: 500px;
    max-width: 100%;
}
#postAnatomy_4 {
    width: 100%;
    height: 500px;
    max-width: 100%;
}

.ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.people_taking {
    color: #fff;
    background-color: #6771dc;
}
.post1 a {
    text-decoration: none;
}
